import React from "react";
import { styles } from "./quotes.css";

export function Quotes() {
  return (
    <div className={styles.container}>
      <p>
        “Am 23 years, and am from Kenya. I have loved your mind relaxing
        technique. It has changed my mind completly. Inner Peace Fellowship
        is My Hero” <span className={styles.attribution}>Eric</span>
      </p>
      <p>
        “Thank you for your wonderful book and instructions. The absence of
        models to explain the unexplainable really resonates with me. I feel I
        have been looking for something like this for a long time.”
        <span className={styles.attribution}>Julie</span>
      </p>

      <p>
        “The ideas in this book have transformed my life. Letting go of
        certainty is such an amazing experience, especially for intellectuals
        trapped in their minds. I could never thank you enough for writing this
        book.” <span className={styles.attribution}>Robert</span>
      </p>
      <p>
        “Reading the Quiz questions opened my heart and my mind to a deeper
        level than I could have even imagined.”{" "}
        <span className={styles.attribution}>Catskills</span>
      </p>

      <p>
        “I have been meditating for many years having done intensive Vipassana
        and Mindfulness meditation yet felt that I had not found the right
        technique for me. This gentle mantra meditation works quite well.
        Thanx.” <span className={styles.attribution}>Lecia</span>
      </p>

      <p>
        “I have been meditating for a very long time (I am 75 years old), but
        have always ended up trying or trying not to try! Your meditation
        instructions have relieved me of this tendency and I really find this
        meditation very relaxing and easy to do.”{" "}
        <span className={styles.attribution}>Julie</span>
      </p>
    </div>
  );
}
