import * as React from "react";

export interface AndroidOnlyProps {
  children: React.ReactElement;
}

export function AndroidOnly({ children }: AndroidOnlyProps) {
  const userAgent =
    typeof window !== "undefined" ? navigator.userAgent.toLowerCase() : "";
  const isAndroid = userAgent?.indexOf("android") !== -1;
  if (isAndroid) return children || null;
  return <React.Fragment />;
}
