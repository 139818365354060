import React from "react";
import { styles } from "./table-of-contents.css";

export function TableOfContents() {
  return (
    <>
      <div className={styles.container}>
        <h3>Table of Contents</h3>

        <h4>Preface</h4>
        <h4>
          Glimpses of God and Reality – How to Integrate Psychedelic Experiences
          of Sacredness
        </h4>

        <p>Part 1 – Why We Have Sacred Experiences With Psychedelics</p>
        <p>Summary</p>
        <p>Introduction</p>
        <p>Inner Peace Fellowship</p>
        <p>Life’s Intelligence</p>
        <p>Life’s Intelligence Has Many Forms</p>
        <p>The Default-mode Network of Our Brain</p>
        <p>Part 2 – A Conversation With Michael</p>

        <h4>A New Beginning</h4>
        <p>How To Approach This New Beginning</p>
        <p>This Astonishing Mystery Called Life</p>
        <p>Another Approach</p>

        <h4>Inner Peace Fellowship Meditation</h4>
        <p>Meditation Instructions</p>
        <p>Introduction</p>
        <p>Your First Meditation</p>
        <p>Daily Practice of Meditation</p>
        <p>Common Mistakes</p>
        <p>Expanding Your Mantra</p>
        <p>Advanced Meditation</p>
        <p>Side Effects and Questions</p>
        <p>Frequently Asked Questions</p>
        <p>Instructions For Advanced Inner Peace Meditation</p>
        <p>Instructions</p>
        <p>Expanded Practice Time</p>
        <p>Discussion</p>
        <p>Side Effects and Cautions</p>

        <h4>Freedom Is Being Present</h4>
        <p>Overview</p>
        <p>Exercises</p>
        <p>Words and Teachings About Freedom</p>
        <p>Our Biggest Problem</p>
        <p>The Rise of Abstract Thinking</p>

        <h4>Who Am I</h4>
        <p>You Are Stardust</p>
        <p>Stardust Comes Alive</p>
        <p>Life Learned How To Learn</p>
        <p>Still The Same Old Mystery</p>

        <h4>Why Be Good</h4>
        <p>How Humans Came to Dominate Earth</p>
        <p>Learning to Control Fire</p>
        <p>Building And Defending An Established Nest</p>
        <p>What Has Value</p>
        <p>How To Build Good Values</p>
        <p>Learning To Do The Right Thing</p>
        <p>Good Values Are Earned and Quiet</p>
        <p>Determining The Values of Others</p>
        <p>The Work You Do</p>
        <p>Are Your Caveman Instincts Making You Crazy?</p>
        <p>We Are One Group</p>

        <h4>Celebrate Life</h4>
        <p>Amazing Grace</p>
        <p>Shri Guru Charanam</p>
        <p>My Sweet Lord</p>
        <p>prātaḥ smarāmi</p>

        <h4>Adi Shankara - Advaita Vedanta</h4>
        <p>Freedom</p>
        <p>Achieving Freedom</p>
        <p>After Thoughts</p>

        <h4>Your Beliefs Quiz</h4>
        <h4>How To Know Whatever Created Life</h4>
        <p>Forward</p>
        <p>The Terrain</p>
        <p>Background</p>
        <p>Power and Authority</p>
        <p>The Business of Selling the You Back Your Higher Power</p>
        <p>The Mystery</p>
        <p>Uncertainty</p>
        <p>Faith and Beliefs</p>
        <p>Abstract Thought and The Fear That Arises From It</p>
        <p>The I That Will Die</p>
        <p>The Questions</p>
        <p>Managing Abstract Thought</p>
        <p>The Mind and The Heart Compete</p>
        <p>An Idea The Heart Can Follow</p>

        <h4>About Us</h4>
        <p>Your Spiritual Home</p>

        <h4>Fairfield Iowa USA</h4>

        <h4>Terms of Use</h4>
      </div>
      <div className={styles.references}>
        <h3>References, Footnotes</h3>
        <p>
          <span>1.</span>Portions of Part 1 were adapted from David Christian’s{" "}
          <i>Big History Project</i>
        </p>
        <p>
          <span>2.</span>“Factor Analysis of the Mystical Experience
          Questionnaire: A Study of Experiences Occasioned by the Hallucinogen
          Psilocybin”; <i>Journal for the Scientific Study of Religion</i>{" "}
          (2012) 51(4):721–737; MacLean, Leoutsakos, Johnson, Griffiths;
          Department of Psychiatry and Behavioral Sciences; Johns Hopkins
          University School of Medicine. The Questionnaire was developed by
          Walter Pahnke (1963, 1969), who referenced the work of W. T. Stace and
          Abraham Maslow.
        </p>
        <p>
          <span>3.</span>“Searching for a baseline: functional imaging and the
          resting human brain”; Gusnard D.A, Raichle, ME; Washington University;{" "}
          <i>Nature Reviews Neuroscience</i>. 2001 Oct;2(10):685-94
        </p>
        <p>
          <span>4.</span>“The entropic brain: a theory of conscious states
          informed by neuroimaging research with psychedelic drugs”; Robin L.
          Carhart-Harris, Robert Leech, Peter J. Hellyer, Murray Shanahan,
          Amanda Feilding, Enzo Tagliazucchi, Dante R. Chialvo, David Nutt;
          Imperial College; Front. Hum. Neurosci., 03 February 2014 | doi:
          10.3389/fnhum.2014.00020
        </p>
        <p>
          <span>5.</span>“Neural correlates of the psychedelic state as
          determined by fMRI studies with psilocybin”; Robin L. Carhart-Harris,
          David Erritzoe, Tim Williams, James M. Stone, Laurence J. Reed,
          Alessandro Colasanti, Robin J. Tyacke, Robert Leech, Andrea L.
          Malizia, Kevin Murphy, Peter Hobden, John Evans, Amanda Feilding,
          Richard G. Wise, and David J. Nutt; Imperial College;
          www.pnas.org/lookup/suppl/doi:10.1073/pnas.1119598109/-/DCSupplemental;
          2012
        </p>
        <p>
          <span>6.</span>“Meditation experience is associated with differences
          in default mode network activity and connectivity”; Judson A. Brewer,
          Patrick D. Worhunsky, Jeremy R. Gray, Yi-Yuan Tang, Jochen Weber, and
          Hedy Kober; Yale University; edited by Marcus E. Raichle, Washington
          University St. Louis, St. Louis, MO;{" "}
          <i>Proceedings of the National Academy of Sciences</i>; December 13,
          2011 108 (50) 20254-20259; https://doi.org/10.1073/pnas.1112029108
        </p>
        <p>
          <span>7.</span>Jeffery A. Martin, <i>The Finders</i>; Integration
          Press; 2019; and “Clusters of Individuals Experiences form a Continuum
          of Persistent Non-Symbolic Experiences in Adults”; 2014, and revised
          in{" "}
          <i>Consciousness: Ideas and Research for the Twenty First Century</i>;
          2020, Vol 8, Issue 8
        </p>
        <p>
          <span>8.</span>E. O. Wilson; <i>The Social Conquest of Earth</i>;
          Liveright, 2013
        </p>
        <p>
          <span>9.</span>“A New Understanding”;
          http://vimeo.com/ondemand/anewunderstandingpa/ or from Amazon.com;
          “The Trip Treatment”; <i>New Yorker</i>; February 9, 2015
        </p>
        <p>
          <span>10.</span>Roland Griffiths; “Johns Hopkins Psilocybin Project –
          Implications for Spirituality & Therapeutics”; at 00:25:49 of video
          presentation to the Psychedelic Science 2017 Conference
        </p>
        <p>
          <span>11.</span>This section was adapted from: E. O. Wilson;{" "}
          <i>The Social Conquest of Earth</i>; Liveright, 2013, and David
          Christian’s <i>Big History Project</i>
        </p>
        <p>
          <span>12.</span>Edward O. Wilson;{" "}
          <i>The Meaning of Human Existence</i>; Liveright, 2014 [and]{" "}
          <i>The Social Conquest of Earth</i>; Liveright, 2013
        </p>
        <p>
          <span>13.</span>You are 93% stardust by mass. The remaining 7% of our
          mass is hydrogen, which was created at the time of the Big Bang and
          thus is not stardust.
        </p>
        <p>
          <span>14.</span>The following sections were adapted from David
          Christian’s <i>Big History Project</i>.
        </p>
        <p>
          <span>15.</span>David Reich; <i>Who We Are And How We Got Here</i>;
          Pantheon, 2018
        </p>
        <p>
          <span>16.</span>Recent surveys by the Pew Foundation have found that
          most people in the United States still believe in God or a universal
          spirit.
        </p>
        <p>
          <span>17.</span>Parts of this chapter were adapted from{" "}
          <i>The Social Conquest of Earth</i> and{" "}
          <i>The Meaning of Human Existence</i> by E. O. Wilson; and from David
          Christian’s <i>Big History Project</i>.
        </p>
        <p>
          <span>18.</span>Edwin Catmull, Creativity, Inc.; Random House, 2014
        </p>
        <p>
          <span>19.</span>Richard Dawkins, <i>River Out of Eden</i>; Basic
          Books, 1995
        </p>
        <p>
          <span>20.</span>Christopher McDougall, <i>Born To Run</i>; Knopf
          Doubleday, 2009
        </p>
        <p>
          <span>21.</span>The six other permanent nest builders are the naked
          mole rat, the Damaraland rat, honeybees, mound-building termites, leaf
          cutter ants, thrips and aphids.
        </p>
        <p>
          <span>22.</span>Steven A. LeBlanc,{" "}
          <i>Constant Battles – Why We Fight</i>; St. Martin’s, 2002
        </p>
        <p>
          <span>23.</span>Stewart Brand, <i>Whole Earth Discipline</i>, “Urban
          Promise”; Penguin, 2009
        </p>
        <p>
          <span>24.</span>Alison Gopnik, “Our Sense of Fairness Is Beyond
          Politics”; <i>Wall Street Journal</i>.; January 21, 2021
        </p>
        <p>
          <span>25.</span>Wikipedia
        </p>
        <p>
          <span>26.</span>
          <i>Viveka-Chudamani (The Crest Jewel of Discrimination)</i>;
          Isherwood, Prabhavananda translation; p. 40
        </p>
        <p>
          <span>27.</span>Ibid; p. 75
        </p>
        <p>
          <span>28.</span>Pew Forum on Religion & Public Life,{" "}
          <i>U.S. Religious Landscape Survey</i> (Washington: Pew, 2008), p.162.
        </p>
        <p>
          <span>29.</span>Ibid; p. 162
        </p>
        <p>
          <span>30.</span>Pew Forum on Religion & Public Life, <i>U.S. Religious Landscape Survey</i> (Washington: Pew, 2008), p.162.
        </p>
        <p>
          <span>31.</span>Barton Swaim quoting Brad Gregory from his book{" "}
          <i>The Unintended Reformation</i> in the <i>Wall Street Journal</i>.
          article “Blame It On Calvin & Luther”; January 14, 2012.
        </p>
        <p>
          <span>32.</span>Marc Andreessen in the <i>Wall Street Journal</i>.
          article “Why Software Is Eating the World”; August 20, 2011.
        </p>
        <p>
          <span>33.</span>Diana Butler Bass,{" "}
          <i>
            Christianity After Religion: The End of Church and the Birth of a
            New Spiritual Awakening
          </i>{" "}
          (New York: HarperCollins 2012)
        </p>
        <p>
          <span>34.</span>Pew Forum on Religion & Public Life, 5 - 7.
        </p>
        <p>
          <span>35.</span>Amy Sullivan, “The Rise of the Nones”, <i>Time</i>,
          March 12, 2012.
        </p>
        <p>
          <span>36.</span>What most people want from their relationship with
          higher power is to have their heart filled with more love and
          brotherhood, to increase happiness and inner peace, to improve luck
          and good fortune, and to keep stuff they’re afraid of away from their
          life.
        </p>
        <p>
          <span>37.</span>Jay Stevens and Ralph Steadman,{" "}
          <i>Storming Heaven; LSD and the American Dream</i>; New York, Grove
          Press, 1998
        </p>
        <p>
          <span>38.</span>Tom Wolfe, <i>Hooking Up</i>; London, Macmillan 2010
        </p>
        <p>
          <span>39.</span>Stevens and Steadman, op. cit.; page 178; references
          the <i>Journal of Neuropsychiatry</i>; Nov-Dec, 1962
        </p>
        <p>
          <span>40.</span>Michael Pollen,{" "}
          <i>
            How to Change Your Mind; What the New Science of Psychedelics
            Teaches Us About Consciousness, Dying, Addiction, Depression, and
            Transcendence
          </i>
          ; New York, Penguin 2018; also, Michael Pollen, "The Trip Treatment";{" "}
          <i>New Yorker</i>; February 9, 2015
        </p>
        <p>
          <span>41.</span>John Edward Dalberg (Lord Acton) in an 1887 letter to
          Mandell Creighton; from “Notable & Quotable” in the{" "}
          <i>Wall Street Journal</i>.; May 19, 2012
        </p>
        <p>
          <span>42.</span>This section was adapted from Edward O. Wilson,{" "}
          <i>The Social Conquest of Earth</i>, and from David Christian’s
          BigHistoryProject.com
        </p>
        <p>
          <span>43.</span>King James Version of the <i>Christian Bible</i>
        </p>
      </div>
    </>
  );
}
